import { format, sub, add } from "date-fns";

export default {
  data() {
    return {
      selectedInvoices: [],
      selectedRowKeys: [],
    };
  },
  watch: {
    $route(value) {
      this.openEditEventModal = false;
      setTimeout(() => {
        value.query.oid ? this.editEvent(value.query.oid) : "";
      }, 300);
    },
  },
  methods: {
    onSelectEventsChange(selectedRowKeys, selectedRows) {
      this.selectedInvoices = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
    },
    closeEditEventModal() {
      this.openEditEventModal = false;
      this.recurringDates = [];
    },
    createRecurringDates() {
      this.loadingRecurringDates = true;
      this.recurringDatesData.event = this.theEvent.raw;
      this.recurringDatesData.event.status = "Pendente";
      this.recurringDatesData.event.attachment = "";
      this.recurringDatesData.event.extra_attachment = "";
      this.recurringDatesData.event.extra_attachment_2 = "";
      this.recurringDatesData.event.other_attachments = "";
      this.recurringDatesData.event.bank_id = "";
      this.recurringDatesData.event.company_credit_card_id = "";
      this.recurringDatesData.event.voyage_id = "";
      this.recurringDatesData.event.payment_methods = "";
      this.recurringDatesData.event.user_id = this.$store.state.userData.id;
      this.recurringDatesData.event.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.recurringDates.forEach((date) => {
        this.recurringDatesData.event.date = date;

        this.$http
          .post("/event/create", this.recurringDatesData.event)
          .then(({ data }) => {
            this.$message.success(data.message);
            this.loadingRecurringDates = false;
            this.recurringDates = [];
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.loadingRecurringDates = false;
          });
      });
    },
    formatMultiDate(date) {
      return date.toLocaleDateString();
    },
    editEvent(id) {
      this.$http
        .post("/event/details?id=" + id)
        .then(({ data }) => {
          this.openEditEventModal = true;
          this.theEvent = data;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    selectDate(date) {
      this.openEventsModal = true;
      this.event.type = "list";
      this.selectDateValue = date;

      if (this.event.mode == "month") {
        this.selectedDate = this.formatMultiDates(date);
        this.event.filters.date = this.formatDateModel(date, "yyyy-MM-dd");
      }

      if (this.event.mode == "year") {
        this.event.type = "list";
        this.selectedDate = this.formatDateModel(date, "MMMM yyyy");
        this.event.filters.date = "";
        this.event.filters.month = this.formatDateModel(date, "M");
        this.event.filters.year = date ? date._d.getFullYear() : "";
      }

      this.event.pagination.perPage = 10;

      setTimeout(() => {
        this.getEvents();
      }, 100);
    },
    filtersChange() {
      this.loadingCalendar = true;
      setTimeout(() => {
        this.getEvents();
      }, 100);
    },
    calendarPanelChange(date, mode) {
      this.event.type = "calendar";
      this.event.mode = mode;
      this.event.filters.date = "";
      if (mode == "month") {
        this.event.filters.month = date._d.getMonth() + 1;
        this.event.filters.year = date._d.getFullYear();
      }

      if (mode == "year") {
        this.event.filters.month = "";
        this.event.filters.year = date._d.getFullYear();
      }

      setTimeout(() => {
        this.getEvents();
      }, 100);
    },
    calendarChange(date) {
      this.selectDateValue = date;
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    calendarNav(nav) {
      let theNew;
      if (nav == "prev") {
        theNew = sub(new Date(this.selectDateValue), {
          years: 0,
          months: 1,
          weeks: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });

        this.selectDateValue = format(theNew, "yyyy-MM-dd");
        this.event.filters.month = format(theNew, "M");
        this.event.filters.year = format(theNew, "yyyy");
      }

      if (nav == "next") {
        theNew = add(new Date(this.selectDateValue), {
          years: 0,
          months: 1,
          weeks: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });

        this.selectDateValue = format(theNew, "yyyy-MM-dd");
        this.event.filters.month = format(theNew, "M");
        this.event.filters.year = format(theNew, "yyyy");
      }

      this.loadingCalendar = true;

      setTimeout(() => {
        this.getEvents();
      }, 200);
    },
    closeDate() {
      this.openEventsModal = false;
      this.event.list = [];
      this.selectedInvoices = [];
      this.loadingCalendar = true;
      this.event.type = "calendar";
      this.event.filters.date = "";

      if (this.event.mode == "year") this.event.filters.month = "";

      this.event.pagination.perPage = 500;

      setTimeout(() => {
        this.getEvents();
      }, 400);
    },
    listEvents() {
      this.getEvents();
      this.loadingCalendar = true;
      this.openCreateEventModal = false;
    },
    getListData(value) {
      let listData;

      listData =
        this.event.calendarList[
          `${value.year()}-${this.monthWithZero(
            value.month() + 1
          )}-${this.dayWithZero(value.date())}`
        ];

      return listData || [];
    },
    getMonthData(value) {
      let listData = [];

      for (let i = 1; i <= 31; i++) {
        if (
          this.event.calendarList[
            `${value.year()}-${this.monthWithZero(
              value.month() + 1
            )}-${this.dayWithZero(i)}`
          ] != undefined
        ) {
          this.event.calendarList[
            `${value.year()}-${this.monthWithZero(
              value.month() + 1
            )}-${this.dayWithZero(i)}`
          ].forEach((date) => {
            listData.push(date);
          });
        }
      }

      return listData || [];
    },
  },
};
